import { Component } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor(public _information: InformationService) { }

  goToContact() {
    const element = document.getElementById('cognisysLogo');
    element.scrollIntoView();
  }
}