import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-buttons',
  templateUrl: './contact-buttons.component.html',
  styleUrls: ['./contact-buttons.component.scss']
})
export class ContactButtonsComponent {
  
  deployed: boolean;

  constructor() { 
    this.deployed = false;
  }

  toggle() {
    this.deployed = !this.deployed;
  }
}