import { Component } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.scss']
})
export class CarrouselComponent {

  constructor(public _information: InformationService) { }
}