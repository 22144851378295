import { WebText } from './web-text.model';
import { Client, Project } from './client.model';

export const INFO = {
  /* METAS */
  keywordsHome : new WebText(
    'Software Factory,it,soluciones informaticas,soluciones a medida,Ingeniería de Software,desarrollo a meida,llave en mano,java,aplicaciones,mobile,OSS,Harvest,Municipalidad,SAT,Claro,DirecTV,Tenaris,Sistema de Autogestión Tributaria', 
    'Software Factory,IT,costum IT solutions,Software Engineering,app,java,mobile,OSS,Harvest,Municipalidad,SAT,Claro,DirecTV,Tenaris,Sistema de Autogestión Tributaria'
  ),
  descriptionHome : new WebText(
    'Utilizamos la Ingeniería de Software para entregar a nuestros clientes las soluciones a medida que necesitan para cumplir con sus objetivos.', 
    'We use Software Engineering to deliver to our customers the solutions as they need to meet their objectives.'
  ),
  ogUrlHome : new WebText(
    '', 
    ''
  ),
  descriptionWe : new WebText(
    'Somos una empresa Software Factory. Creamos soluciones personalizadas para cada uno de nuestros clientes, garantizando un servicio de excelencia. Nuestro diferencial es la cercania con nuestro clientes, convirtiendonos en sus socios.', 
    'We are a Software Factory company. We create customized solutions for each of our clients, guaranteeing an excellent service. Our differential is the proximity to our customers, becoming their partners.'
  ),
  keywordsWe : new WebText(
    'Software Factory,it,soluciones informaticas,soluciones a medida,Ingeniería de Software', 
    'Software Factory,IT,costum IT solutions,Software Engineering'
  ),
  ogUrlWe : new WebText(
    '', 
    ''
  ),
  descriptionMake : new WebText(
    'Desarrollamos software a medida, utilizando la metodología de desarrollo que más se acomode a nuestros clientes; manteniendo como premisa la calidad del producto y el cumplimiento de sus KPIs.', 
    'We develop custom software, using the development methodology that best suits our customers; maintaining as premise the quality of the product and the fulfillment of its KPIs.'
  ),
  keywordsMake : new WebText(
    'software a medida,soluciones a medida,metodologia de desarrollo,Desarrollos llave en mano', 
    'costum software,costum IT solutions,development methodologies,Turnkey Developments'
  ),
  ogUrlMake : new WebText(
    '', 
    ''
  ),
  descriptionCreate : new WebText(
    'Tenemos gran experiencia desarrollando software a medida y consultoria, en rubros como Telecomunicaciones, Bancario, Financiero, Energía y Municipios.', 
    'We have great experience developing custom software and consulting, in areas such as Telecommunications, Banking, Financial, Energy and Municipalities.'
  ),
  keywordsCreate : new WebText(
    'Claro,DirecTV,OSS,Software Telecomunicaciones,Banco Hipotecario,Banco Comafi,Pepsico,Efectivo Si,Cetelem,software municipal', 
    'Claro,DirecTV,OSS,Banco Hipotecario,Banco Comafi,Pepsico,Efectivo Si,Cetelem,Telecommunications Software,municipality software'
  ),
  ogUrlCreate : new WebText(
    '', 
    ''
  ),
  descriptionJob : new WebText(
    'Brindamos la posibilidad de iniciar, capacitarse, desarrollarse y alcanzar las especializacion que estes buscando.', 
    'We offer the possibility of starting, training, developing and reaching the specializations you are looking for.'
  ),
  keywordsJob : new WebText(
    'primer trabajo IT,primer trabajo informatica,desarrollador,tester,analista,devops', 
    'first IT job,developer,tester,analyst,devops'
  ),
  ogUrlJob : new WebText(
    '', 
    ''
  ),

  /* MENU */
  home: new WebText('Home', 'Home'),
  menuWe: new WebText('Somos', 'About'),
  menuMake: new WebText('Hacemos', 'Our Services'),
  menuCreate: new WebText('Creamos', 'Our Work'),
  menuJob: new WebText('Trabajá con nosotros', 'Work with us'),
  menuContact: new WebText('Contacto', 'Contact us'),
  
  slogan: new WebText('SOLUCIONES INFORMÁTICAS A MEDIDA', 'CUSTOMIZED IT SOLUTIONS'),

  /* BUTTONS */
  buttonKnowInfo: new WebText('conocé más', 'more info'),
  buttonMoreInfo: new WebText('más info', 'more info'),
  buttonSeeMore: new WebText('ver más', 'more info'),
  buttonJob: new WebText('cargá tu CV', 'attach you CV?'),

  /* TEXT FOR CARROUSEL */
  textCar1: new WebText(
    '<span class="banner-title-size-phone font-size-6 font-futura">SOMOS</span> una empresa<br><span class="banner-title-size-phone font-size-6 font-futura">SOFTWARE FACTORY</span><br>referente en soluciones<br>informáticas personalizadas', 
    '<span class="banner-title-size-phone font-size-6 font-futura">WE ARE</span> a <br><span class="banner-title-size-phone font-size-6 font-futura">SOFTWARE FACTORY</span> company, a reference in customized IT solutions'
  ),
  textCar2: new WebText(
    '<span class="banner-title-size-phone font-size-6 font-futura">HACEMOS<br>SOFTWARE A MEDIDA</span><br>utilizando diferentes metodologías en base a los requerimientos de nuestros clientes', 
    '<span class="banner-title-size-phone font-size-6 font-futura">WE DO CUSTOM SOFTWARE</span> using different methodologies based on the requirements of our customers'
  ),
  textCar3: new WebText(
    '<span class="banner-title-size-phone font-size-6 font-futura">TRABAJÁ con<br>NOSOTROS</span><br>¿Querés aprender y desarrollarte en sistemas?<br class="no-display-phone">Entonces, ¡tenemos una propuesta para vos!', 
    '<span class="banner-title-size-phone font-size-6 font-futura">WORK WITH US</span><br>Do you want to learn and develop in systems? So, we have a proposal for you!'
  ),

  weTileProject: new WebText('PROYECTOS', 'PROJECT'),
  weTileProjectText: new WebText('realizados con dedicación y responsabilidad', 'made with dedication and responsibility'),
  weTileSolution: new WebText('SOLUCIONES', 'SOLUTIONS'),
  weTileSolutionText: new WebText('personalizadas para cada uno de nuestros clientes', 'customized for each of our clients'),
  
  makeTileTitle: new WebText('DESARROLLAMOS SOFTWARE A MEDIDA', 'WE DEVELOP CUSTOM SOFTWARE'),
  makeTileText: new WebText(
    'Para nosotros es imprescindible poner el foco en nuestros clientes y convertirnos en parte de sus equipos.<br>Esto nos permite innovar, trascender y cumplir con los objetivos propuestos.<br>Contamos con diferentes metodologías de trabajo.', 
    'For us it is essential to focus on our customers and become part of their teams.<br>This allows us to innovate, transcend and fulfill the proposed objectives.<br>We have different work methodologies.'
  ),

  contactHeader: new WebText(
    'ESCRIBINOS a <a class="access" href="mailto:info@cognisys.com.ar" target="_blank">info@cognisys.com.ar</a> o llená los datos a continuación<br>contándonos en qué podemos ayudarte y te responderemos a la brevedad.',
    'WRITE us to <a class="access" href="mailto:info@cognisys.com.ar" target="_blank">info@cognisys.com.ar</a> or fill in the information below <br> telling us how we can help you and we will respond as soon as possible.'
  ),

  contactName: new WebText('Nombre:', 'Name:'), 
  contactCompany: new WebText('* Empresa:', '* Company:'), 
  contactMail: new WebText('* Mail:', '* e-Mail:'), 
  contactDetail: new WebText('* Necesitamos:', '* I need:'), 
  
  contactTechStageTitle: new WebText('¿En qué etapa podemos ayudarte?', 'At what stage can we help you?'),
  contactDev: new WebText('Desarrollo', 'Development'),
  contactDis: new WebText('Diseño UI/UX', 'UX/UI Design'),
  contactAna: new WebText('Análisis de Requerimientos', 'Requirements Analysis'),
  contactImp: new WebText('Implementación', 'Implement'),
  contactMan: new WebText('Mantenimiento', 'Maintenance'),
  contactQC: new WebText('Control de Calidad (QC)', 'Quality Control'),
  contactQA: new WebText('Aseguramiento de Calidad (QA)', 'Quality Assurance'),
  
  contactTechSolutionTitle: new WebText('¿Qué solución informática estás buscando?', 'What IT solution are you looking for?'),
  contactWeb: new WebText('Aplicación Web', 'Web App'),
  contactMob: new WebText('Aplicación Móvil', 'Mobile App'),
  contactDesk: new WebText('Aplicación de Escritorio', 'Desktop App'),
  contactWS: new WebText('WebServices', 'WebServices'),
  contactMic: new WebText('Microservicios', 'Microservices'),
  
  contactOth: new WebText('Otro', 'Other'),

  contactAddress: new WebText('Paraguay 5529 PB "A" - (C1425CCN) CABA, Argentina', 'Paraguay 5529 Basement "A" - (C1425CCN) CABA, Argentina'),

  sendMail: new WebText('enviar', 'send'),
  moreDetails: new WebText('+ info', '+ info'),
  lessDetails: new WebText('- info', '- info'),

  findUs: new WebText('Encontranos', 'Find us'),

  weDesc: new WebText(
    'Somos una empresa Software Factory. Creamos soluciones personalizadas para cada uno de nuestros clientes, garantizando un servicio de excelencia. Llevamos adelante nuestros proyectos con dedicación y responsabilidad, generando un vínculo de confianza con quienes nos eligen para desarrollar e implementar la mejor solución.<br>Nuestro propósito es ser referentes en soluciones informáticas, creadas exclusivamente para cada cliente, acompañándolo con total compromiso y dedicación en cada paso del desarrollo de sus proyectos.', 
    'We are a Software Factory company. We create customized solutions for each of our clients, guaranteeing an excellent service. We carry out our projects with dedication and responsibility, generating a bond of trust with those who choose us to develop and implement the best solution.<br>Our purpose is to be referents in computer solutions, created exclusively for each client, accompanying him with total commitment and dedication at every step of the development of his projects.'
  ),
  weValues: new WebText('Nuestros Valores', 'Our Values'),
  weRespect: new WebText('<img src="assets/img/icons/check.png" class="check"> RESPETO', '<img src="assets/img/icons/check.png" class="check"> RESPECT'),
  weRespectText: new WebText(
    'entre nosotros y hacia toda persona que se vincule con la empresa. Trabajamos para mantener la equidad en nuestras decisiones, en un ambiente democrático y colaborativo.', 
    'between us and towards any person who links with the company. We work to maintain equity in our decisions, in a democratic and collaborative environment.'
  ),
  weCompromise: new WebText('<img src="assets/img/icons/check.png" class="check"> COMPROMISO', '<img src="assets/img/icons/check.png" class="check"> COMPROMISE'),
  weCompromiseText: new WebText(
    'con las personas (colaboradores, clientes y proveedores) y con cada uno de nuestros proyectos.',
    'with people (collaborators, customers and suppliers) and with each of our projects.'
  ),
  weDedication: new WebText('<img src="assets/img/icons/check.png" class="check"> DEDICACIÓN', '<img src="assets/img/icons/check.png" class="check"> DEDICATION'),
  weDedicationText: new WebText(
    'ponemos el máximo entusiasmo en lo que hacemos y trabajamos con pasión y esfuerzo. Nos entregamos a cada proyecto con gran responsabilidad en busca de los mejores resultados.', 
    'We put the maximum enthusiasm in what we do and work with passion and effort. We deliver to each project with great responsibility in search of the best results.'
  ),
  weChanges: new WebText('<img src="assets/img/icons/check.png" class="check"> DISPOSICIÓN AL CAMBIO', '<img src="assets/img/icons/check.png" class="check"> WILLING TO CHANGE'),
  weChangesText: new WebText(
    'estamos abiertos a la escucha como fuente de creatividad e innovación. Acompañamos los cambios y sostenemos una búsqueda constante de nuevas ideas y desafíos.', 
    'We are open to listening as a source of creativity and innovation. We accompany the changes and We maintain a constant search for new ideas and challenges.'
  ),

  weCreators: new WebText('Creadores', 'Creators'),
  weMBenz: new WebText(
    'Hace más de 20 años, desafió sus límites y decidió independizarse. Se graduó de Ingeniero en Sistemas en 2001. Inquieto y de espíritu curioso, está siempre en búsqueda de nuevas ideas y desarrollos. Cree fuertemente en el trabajo en equipo para conseguir grandes resultados. Se involucra con compromiso en cada proyecto, desde una perspectiva estratégica, aportando todos sus conocimientos y sumando agilidad.', 
    'More than 20 years ago, he challenged his limits and decided to become independent. He graduated in Systems Engineer in 2001. Restless and curious in spirit, he is always in search of new ideas and developments. Believe strongly in teamwork to achieve great results. He engages with commitment in each project, from a strategic perspective, contributing all his knowledge and adding agility.'
  ),
  weMCestoni: new WebText(
    'Ingeniero en Sistemas, pragmático y resolutivo. Con más de 25 años de experiencia en el desarrollo de sistemas, ha concretado cientos de proyectos y creado múltiples aplicaciones para diferentes clientes. Entiende la importancia de estar siempre a la vanguardia y crear soluciones personalizadas, con visión innovadora. Participa activamente de los proyectos de la empresa, aportando experiencia y resolución creativa.', 
    'Systems Engineer, pragmatic and decisive. With more than 25 years of experience in systems development, he has completed hundreds of projects and created multiple applications for different clients. Understand the importance of always being at the forefront and creating customized solutions, with innovative vision. Actively participate in the company`s projects, providing experience and creative resolution.'
  ),

  /* MAKE */
  makeDesc: new WebText(
    'Desarrollamos software a medida. Para nosotros es imprescindible poner el foco en nuestros clientes y convertirnos en parte de sus equipos. Esto nos permite innovar, trascender y cumplir con los objetivos propuestos, trabajando cada una de las siguientes etapas:',
    'We develop custom software. For us it is essential to focus on our customers and become part of their teams. This allows us to innovate, transcend and fulfill the proposed objectives, working each of the following stages:'
  ),
  makePlan: new WebText('Planificación de concepto', 'Concept planning'),
  makeReq: new WebText('Definicion de requisitos', 'Requirement Definition'),
  makeDis: new WebText('Diseño', 'Design'),
  makeDevtest: new WebText('Desarrollo y pruebas', 'Dev a Test'),
  makeImp: new WebText('Puesta en marcha', 'Implemnent'),
  makeMan: new WebText('Operaciones y mantenimiento', 'Maintenance'),

  makeMethodTitle: new WebText('Contamos con diferentes metodologías de trabajo', 'We have different work methodologies'),
  makeKeyTitle: new WebText('Desarrollos llave en mano', 'Turnkey Developments'),
  makeKey: new WebText(
    'En este caso, recibimos el pedido del cliente y realizamos una propuesta. Una vez aprobada la misma, se empieza a trabajar la totalidad del proyecto y se entrega al cliente el trabajo cerrado para su implementación.', 
    'In this case, we receive the customer`s order and make a proposal. Once the project has been approved, the entire project begins to work and the work closed for its implementation is delivered to the client.'
  ),
  makePeopleTitle: new WebText('Recursos on-site/off site', 'On-site / off site resources'),
  makePeople: new WebText(
    'Brindamos la posibilidad de sumar a nuestros colaboradores al staff del cliente durante el tiempo del proyecto. De esta manera, agilizamos el proceso de trabajo.', 
    'We offer the possibility of adding our collaborators to the client`s staff during the time of the project. In this way, we streamline the work process.'
  ),
  makePackTitle: new WebText('Pack de horas', 'Pack of hours'),
  makePack: new WebText(
    'Bajo esta modalidad acordamos con nuestros clientes una cantidad de horas mensuales, en las cuales se lleva adelante el proyecto. En ese tiempo, se realizan los requerimientos, cambios, ajustes, presentaciones y entregas. Lo más destacado de esta forma de trabajo es su flexibilidad, ya que el cliente puede pedir todo el desarrollo o ir armándolo por módulos de acuerdo a sus necesidades.', 
    'Under this modality we agree with our clients a number of monthly hours, in which the project is carried out. At that time, the requirements, changes, adjustments, presentations and deliveries are made. The highlight of this form of work is its flexibility, since the client can request the entire development or assemble it by modules according to their needs.'
  ),
  makeFinale: new WebText('¿Por qué elegirnos?', 'Why choose us?'),
  makeFinaleText: new WebText(
    'Porque nos comprometemos con cada proyecto que llevamos adelante. Somos un gran equipo que siempre busca innovar y crear soluciones a medida. Te acompañamos en cada paso del desarrollo de tu requerimiento, involucrándonos todos; directores, colaboradores y especialistas, para asegurarte un trato personalizado y el mejor resultado.', 
    'Because we commit to each project that we carry out. We are a great team that always seeks to innovate and create customized solutions. We accompany you at every step of the development of your requirement, involving all of us; directors, collaborators and specialists, to ensure a personalized service and the best result.'
  ),
  makeProposal: new WebText('Quiero mi propuesta', 'I want my proposal'),

  /* JOB */
  jobTitle: new WebText('TRABAJÁ con<br>NOSOTROS', 'WORK with US'),
  jobDesc: new WebText(
    'Si estás buscando aprender y desarrollarte laboralmente junto a un equipo comprometido y profesional ¡tenemos una propuesta para vos!', 
    'If you are looking to learn and develop professionally with a committed and professional team, we have a proposal for you!'
  ),
  jobFirstTitle: new WebText('Mi primer trabajo', 'My first job'),
  jobFirst: new WebText(
    'Si aún estás cursando tu carrera y no contás con experiencia laboral en el área de sistemas ¡nosotros te ofrecemos ser parte de nuestro #dreamteam! Vas a poder aprender y trabajar con diversas herramientas y tecnologías que contribuirán a tu desarrollo profesional, trabajando junto a empresas de primer nivel.', 
    'If you are still studying and do not have work experience in the systems area, we offer you to be part of our #dreamteam! You will be able to learn and work with various tools and technologies that will contribute to your professional development, working together with top-level companies.'
  ),
  jobStudentTitle: new WebText('¡Ya me recibí!', 'I already received!'),
  jobStudent: new WebText(
    'Si ya terminaste tus estudios y tenés algo de experiencia en empresas del rubro ¡también tenemos una oportunidad para vos! Te invitamos a sumarte a nuestro equipo y trabajar en un ambiente cálido, respetuoso y profesional con flexibilidad horaria y otros beneficios.', 
    'If you have finished your studies and have some experience in companies in the field, we also have an opportunity for you! We invite you to join our team and work in a warm, respectful and professional environment with flexible hours and other benefits.'
  ),
  jobCV: new WebText('Dejanos tu CV a continuación y si aparece una búsqueda acorde a tu perfil ¡te contactamos!', 'Leave your CV below and if a search appears according to your profile, we will contact you!'),
  jobCVButton: new WebText('Cargar CV', 'Load CV'),

  fieldValidation: new WebText('Complete el campo', 'Complete this field'),
  emailValidation: new WebText('Ingrese un mail válido', 'Complete with a valid email'),

  /* CIENTES */
  clients: [
    new Client('claro.jpg', 'Claro', 'cla', ['filterTelecommunications'], [
      new Project(
        'SGT', 
        2007, new WebText('2007 - actualmente', '2007 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'OSS (Operation Support System)– B/OSS (Bussiness Support System): Sistema para el Aprovisionamiento de Servicios de Telefonía e Internet (Claro).', 
          'OSS (Operation Support System) - B/OSS (Bussiness Support System): System for the Provision of Telephony and Internet Services (Claro).'
        ),
        new WebText(
          'OSS (Operation Support System)– B/OSS (Bussiness Support System): Sistema para el Aprovisionamiento de Servicios de Telefonía fija e Internet, tanto residencial como empresarial. Permite a los operadores la configuración de la Work Order, con la vinculación del CPE; su configuración interna e impacto en los distintos gestores de provisioning.<br>El sistema integra 7 productos empresariales y 1 producto residencial; trabajando sobre las tecnologías de acceso como WiMAX, FTTx, HFC. La herramienta administra los distintos elementos de red de cada topología, controla el estado de cada servicio y centraliza la información proveniente de los distintos sistemas CRMs.', 
          'OSS (Operation Support System) - B/OSS (Bussiness Support System): System for the Provision of Fixed Telephony and Internet Services, both residential and business. It allows operators to configure the Work Order, with the linkage of the CPE; its internal configuration and impact on the different provisioning managers. <br> The system integrates 7 business products and 1 residential product; working on access technologies such as WiMAX, FTTx, HFC. The tool manages the different network elements of each topology, controls the status of each service and centralizes the information coming from the different CRM systems.'
        ),
      ),
      new Project(
        'Interfáz SAP', 
        2018, new WebText('2018 - 2019', '2018 - 2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Migración del sistema CRM a SAP, traspasando los servicios de los clientes y adecuando el sistema SGT para actualizar las interfaces de registro de Work Orders.<br>Posteriormente se incorporan las interfaces para recepción de Work Orders para procesos de provisiones automáticas por lotes (Suspensiones, Reconexiones, Bajas de servicios).', 
          'Migration of the CRM system to SAP, transferring customer services and adapting the SGT system to update the Work Orders registration interfaces. <br> Subsequently, the interfaces for receiving Work Orders are incorporated for automatic batch provision processes (Suspensions) , Reconnections, Low Services).'
        ),
      ),
      new Project(
        'Stealth Cluster Provisioning', 
        2018, new WebText('2018 - 2019', '2018 - 2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Se implementa una arquitectura de Cluster para el aprovisionamiento de servicios provenientes del CRM Stealth. Se reduce el tiempo de procesamiento de lotes automáticos y permite soportar el incremento de la demanda.', 
          'A Cluster architecture is implemented for the provisioning of services from the Stealth CRM. It reduces the processing time of automatic batches and allows to support the increase in demand.'
        ),
      ),
      new Project(
        'TR069', 
        2018, new WebText('2018 - 2019', '2018 - 2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Se incorpora este equipo como nuevo Gestor para el aprovisionamiento de configuraciones de IAD, para servicios GPON y LTE, soportando múltiples modelos y configuraciones como características de Telefonía, Internet y WiFi. Además de las configuraciones tradicionales, se incorporan las configuraciones de un segundo WiFi y se permite modificar la configuración de Bridge y DMZ.', 
          'This equipment is incorporated as a new Manager for the provisioning of IAD configurations, for GPON and LTE services, supporting multiple models and configurations such as Telephony, Internet and WiFi features. In addition to the traditional configurations, the configurations of a second WiFi are incorporated and the configuration of Bridge and DMZ can be modified.'
        ),
      ),
      new Project(
        '300 Megas', 
        2018, new WebText('2018', '2018'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Adaptación del sistema para permitir esta nueva configuración de velocidad para la tecnología GPON.', 
          'System adaptation to allow this new speed setting for GPON technology.'
        ),
      ),
      new Project(
        'GPON Nokia', 
        2018, new WebText('2018 - 2019', '2018 - 2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Incorporación de la Central Nokia, para soportar la creciente demanda de servicios Residenciales. para la tecnología GPON. Se adecuó el sistema SGT para el manejo transaccional a este nuevo equipo. De esta forma el sistema puede decidir a qué Central (Nokia Huawei) envía la información, a partir de la ubicación del cliente. En 2019, se incorporan los servicios empresariales.', 
          'Incorporation of Nokia Central, to support the growing demand for residential services. for GPON technology. The SGT system for transactional management was adapted to this new equipment. In this way the system can decide to which Central (Nokia Huawei) sends the information, from the customer´s location. In 2019, business services are incorporated.'
        ),
      ),
      new Project(
        'VPN 2x1', 
        2018, new WebText('2018', '2018'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Claro sale al mercado con la oferta de un enlace adicional de VPN, para que los clientes cuenten con redundancia. Se adapta el sistema de forma tal que permita dicha la configuración dentro de la misma Work Order.', 
          'Claro goes on the market with the offer of an additional VPN link, so that customers have redundancy. The system is adapted in such a way as to allow said configuration within the Work Order itself.'
        ),
      ),
      new Project(
        'LTE', 
        2018, new WebText('2018 - 2019', '2018 - 2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Incorporación de la nueva tecnología al sistema de provisiones, las interfaces de creación de Órdenes de Servicio, por los distintos CRMs.', 
          'Incorporation of the new technology into the provisions system, the Service Order creation interfaces, by the different CRMs.'
        ),
      ),
      new Project(
        'GPON Flex', 
        2019, new WebText('2019', '2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Las nuevas placas de GPON Flex permiten duplicar la capacidad de clientes. No todos los CPEs lo soportan, y por tanto el sistema SGT tiene que determinar qué provisiones se realizan a través de puertos Flex y cuáles no. ', 
          'The new GPON Flex plates allow to double the capacity of customers. Not all CPEs support it, and therefore the SGT system has to determine which provisions are made through Flex ports and which are not.'
        ),
      ),
      new Project(
        'Internet Empresarial 2.0', 
        2018, new WebText('2018', '2018'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Incorporación de la tecnología LTE al producto Internet Empresarial.', 
          'Incorporation of LTE technology into the Internet Business product.'
        ),
      ),
      new Project(
        'WID Planning', 
        2019, new WebText('2019', '2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Se crea un nuevo módulo para registrar todo el trabajo relacionado al estudio de factibilidad de una instalación para servicios empresariales. Este módulo permite la carga del detalle, documentos relacionados y manejo de versiones.', 
          'A new module is created to record all the work related to the feasibility study of an installation for business services. This module allows the loading of detail, related documents and version management.'
        ),
      ),
      new Project(
        'WID', 
        2016, new WebText('2016 - actualmente', '2016 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Renovación del sistema Web de Ingeniería de Detalle. Se construye una aplicación Web que gestiona la administración de proyectos de Instalación de redes de Claro. El sistema permite la carga del detalle, documentación relacionada, registro de equipos, datos del cliente y el versionado de las actualizaciones.', 
          'Renewal of the Detailed Engineering Web system. A Web application is built that manages the administration of Claro Network Installation projects. The system allows the loading of the detail, related documentation, equipment registration, customer data and the versioning of the updates.'
        ),
      ),
      new Project(
        'MultiVRF', 
        2019, new WebText('2019', '2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Se modifica la configuración enviada por el sistema SGT, a los Routers de servicios Empresariales; para que se los pueda acceder de forma remota y así brindar un mantenimiento de este tipo.', 
          'The configuration sent by the SGT system to the Business Services Routers is modified; so that they can be accessed remotely and thus provide such maintenance.'
        ),
      ),
      new Project(
        'IPTV', 
        2018, new WebText('2018', '2018'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Servicio de Cable por Internet, incorporándose al producto masivo, para la tecnología GPON; destinado para Paraguay y Argentina. Esto requirió una modificación en todas las fases del aprovisionamiento: comunicación con CRMs, configuraciones de equipos, templates y comunicaciones con la central correspondiente.', 
          'Internet Cable Service, joining the mass product, for GPON technology; destined for Paraguay and Argentina. This required a modification in all phases of provisioning: communication with CRMs, equipment configurations, templates and communications with the corresponding exchange.'
        ),
      ),
      new Project(
        'Convergencia', 
        2013, new WebText('2013 - actualmente', '2013 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Se crea un sistema con un alto nivel de parametrización, para la administración de Templates de provisión de Servicios Empresariales. Este desarrollo permitió reducir los tiempos de administración, como la reducción de errores, al estar centralizados.', 
          'A system with a high level of parameterization is created for the administration of Templates for the provision of Business Services. This development allowed reducing administration times, such as error reduction, by being centralized.'
        ),
      ),
      new Project(
        'Cisco IOS XR', 
        2015, new WebText('2015 - 2019', '2015 - 2019'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Incorporación de nuevos Routers CISCO con este SO, para los servicios empresariales de RPV, RPV Lite, CGIP e Internet Empresarial. Se crea un nuevo módulo para las comunicaciones con dichos Routers a través de una secuencia de comandos.', 
          'Incorporation of new CISCO routers with this OS, for the business services of RPV, RPV Lite, CGIP and Internet Business. A new module is created for communications with these routers through a script.'
        ),
      ),
      new Project(
        'CRM Stealth', 
        2011, new WebText('2011 - actualmente', '2011 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Desarrollo de interfaces para la incorporación del CRM Stealth. Este desarrollo consistió en el reconocimiento de los mensajes, detección de errores, control de estado de servicios, manejo de servicios de Post-venta y aprovisionamiento automáticos por lotes.', 
          'Development of interfaces for the incorporation of the Stealth CRM. This development consisted of message recognition, error detection, service status control, handling of post-sale services and automatic batch provisioning.'
        ),
      ),
      new Project(
        'Servicio de Asistencia de Facturación', 
        2015, new WebText('2015 - 2016', '2015 - 2016'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Se implementó una solución que conecta el sistema de facturación de Claro en Uruguay (ubicado en sus Centros de Atención al Cliente) con la DGI.', 
          'A solution was implemented that connects Claro´s billing system in Uruguay (located in its Customer Service Centers) with the DGI.'
        ),
      ),
      new Project(
        'Migrador de Servicios GPON', 
        2018, new WebText('2018', '2018'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText(
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', 
          ''
        ),
        new WebText(
          'Se creó un nuevo módulo para permitir migrar un splitter con todos los servicios asociados, conectados en un puerto de OLT a otro de la misma u otra OLT. Su objetivo fue automatizar la migración de los servicios de GPON.', 
          'A new module was created to allow a splitter to be migrated with all associated services, connected in an OLT port to another of the same or another OLT. Its objective was to automate the migration of GPON services,'
        ),
      ),
    ]),
    new Client('directv.png', 'DirecTV', 'dtv', ['filterTelecommunications'], [
      new Project(
        'Taquion - Provisioning Suite Solution', 
        2015, new WebText('2015 - actualmente', '2015 - now'),
        ['filterImportant', 'filterDevelopment'],
        new WebText('Suite de Aplicaciones, que conectan el sistema CRM con los servicios de provisión de ingeniería.', 'Suite of Applications, which connect the CRM system with engineering provision services.'),
        new WebText(
          'Desarrollamos una Suite de Aplicaciones que conecta el sistema CRM con los servicios de provisión de ingeniería, para abastecer las tecnologías de LTE, WiMAX, FTTH y Satelital. La herramienta fue diseñada para tener una rápida escalabilidad y un alto nivel de parametrización; de modo tal que permita manejar la carga de trabajo de forma dinámica.<br>La solución fue diseñada y desarrollada para soportar 200 peticiones de provisión por segundo; procesando según las prioridades configuradas y cuenta con un sistema automático de reproceso.', 
          'We develop an Application Suite that connects the CRM system with engineering provision services, to supply LTE, WiMAX, FTTH and Satellite technologies. The tool was designed to have fast scalability and a high level of parameterization; in a way that allows you to handle the workload dynamically.<br>The solution was designed and developed to support 200 supply requests per second; processing according to the configured priorities and has an automatic reprocessing system.'
        ),
      ),
      new Project(
        'Taquion - ISR', 
        2016, new WebText('2016 - actualmente', '2016 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('ISR es un módulo de la suite que registra la información resultante de las provisiones.', 'ISR is a module of the suite that records the information resulting from the provisions.'),
        new WebText(
          'ISR es un módulo de la suite que registra la información resultante de las provisiones. Esto permite ver el resultado de la provisión con los datos del equipo luego de ella.<br>También, se implementa un sistema de QA, para analizar periódicamente el estado de los CPEs, pudiendo configurar las periodicidades de análisis en base a las antigüedades de las provisiones; por tecnología.', 
          'ISR is a module of the suite that records the information resulting from the provisions. This allows you to see the result of the provision with the equipment data after it.<br>Also, a QA system is implemented, to periodically analyze the status of the CPEs, being able to configure the analysis periodicities based on the antiquities of the provisions; by technology'
        ),
      ),
      new Project(
        'Taquion - Averías Masivas', 
        2019, new WebText('2019 - actualmente', '2019 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('En dicho módulo se permite al usuario registrar un caso, configurando el tiempo de resolución del mismo y seleccionando la zona y equipos afectados.', 'In this module the user is allowed to register a case, setting the resolution time of the case and selecting the affected area and equipment.'),
        new WebText(
          'Se incorpora al sistema un módulo de gestión de Averías Masivas. En dicho módulo se permite al usuario registrar un caso, configurando el tiempo de resolución del mismo y seleccionando la zona y equipos afectados. Esto proporciona información al servicio del Call Center, para determinar si el CPE de un cliente se encuentra en una avería y de ser así, en cuanto tiempo se encontrará solucionado.', 
          'A massive breakdown management module is incorporated into the system. In this module the user is allowed to register a case, setting the resolution time of the case and selecting the affected area and equipment. This provides information to the Call Center service, to determine if a customer´s CPE is in a fault and if so, how long it will be resolved.'
        ),
      ),
      new Project(
        'Spark - Satelital Provisioning', 
        2016, new WebText('2016', '2016'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Aplicación Android utilizada por los instaladores durante el proceso de instalación de Internet Satelital.', 'Android application used by installers during the installation process of Satellite Internet.'),
        new WebText(
          'Aplicación Android utilizada por los instaladores durante el proceso de instalación de Internet Satelital. Facilita la configuración del modem y calibración de la antena, en tres simples pasos. Fue diseñada para funcionar sin Internet, debido a que las instalaciones se realizan en zonas sin cobertura.', 
          'Android application used by installers during the installation process of Satellite Internet. It facilitates the modem configuration and antenna calibration, in three simple steps. It was designed to work without Internet, because the installations are carried out in areas without coverage.'
        ),
      ),
    ]),
    new Client('mvl.jpg', 'Mun. Vicente López', 'mvl', ['filterMunicipalities'], [
      new Project(
        'SAT Web', 
        2015, new WebText('2015 - actualmente', '2015 - now'),
        ['filterImportant', 'filterDevelopment'], 
        new WebText('Aplicación web que permite a los vecinos del municipio de Vicente López realizar diferentes gestiones, configurar alertas y adherirse a la boleta electrónica.', 'Web application that allows residents of the municipality of Vicente López to carry out different procedures, configure alerts and adhere to the electronic ticket.'),
        new WebText(
          '<b>Premio CIPPEC 2017 Innovación Económica.</b><br><br>Aplicación web que permite a los vecinos del municipio de Vicente López realizar consultas de deuda, generar recibos de pago y realizar pagos de manera online. Todo esto sobre las tasas de ABL, Automotores y Motovehículos.<br>En constante evolución, actualmente seguimos trabajando, incorporando mejoras y nuevas funcionalidades.<br><br><a href="https://www.vicentelopez.gov.ar/ingresos-publicos/novedades/detalle/N-20170821" target="_blank">www.vicentelopez.gov.ar/ingresos-publicos/novedades/detalle/N-20170821</a>', 
          '<b>2017 CIPPEC Economic Innovation Award.</b><br><br>Web application that allows residents of the municipality of Vicente López to make debt inquiries, generate payment receipts and make payments online. All this on the rates of ABL, Automotive and Motor Vehicles.<br>In constant evolution, we are currently continuing to work, incorporating improvements and new features.<br><br><a href="https://www.vicentelopez.gov.ar/ingresos-publicos/novedades/detalle/N-20170821" target="_blank">www.vicentelopez.gov.ar/ingresos-publicos/novedades/detalle/N-20170821</a>'
        ),
      ),
      new Project(
        'Portal de Compras', 
        2014, new WebText('2014 - 2017', '2014 - 2017'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Sistema de administración de Pliegos y Licitaciones.', 'Bid Administration System'),
        new WebText(
          '“Compras y Licitaciones” como fue denominada, se creó para facilitar los trabajos administrativos interdepartamentales de la gestión de Pliegos y Licitaciones. Este sistema permite la confección de los mismos, sus seguimientos, revisiones, ediciones y aprobaciones. Cuenta con una parte pública, en donde se permite a los ciudadanos acceder a la información, siguiendo los lineamientos de transparencia de la información.', 
          '“Purchases and Tenders” as it was called, was created to facilitate the interdepartmental administrative work of the Bid and Bid management. This system allows the preparation of them, their follow-ups, revisions, editions and approvals. It has a public part, where citizens are allowed to access information, following the guidelines for information transparency.'
        ),
      ),
      new Project(
        'Portal de Proveedores', 
        2015, new WebText('2014 - 2017', '2014 - 2017'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Plataforma de acceso para Proveedores', 'Supplier Access Platform'),
        new WebText(
          'Esta aplicación fue creada como herramienta administrativa y permite a los proveedores, gestionar la registración, actualizar sus datos y consultar Ordenes de Compra y Facturas. Cuenta con una sección para que los empleados, hagan una revisión de los datos suministrados, puedan solicitar más información o documentación, y finalmente dar su aprobación o rechazo. ', 
          'This application was created as an administrative tool and allows suppliers to manage registration, update their data and consult Purchase Orders and Invoices. It has a section for employees to review the data provided, request more information or documentation, and finally give their approval or rejection.'
        ),
      ),
      new Project(
        'SAT Mobile', 
        2017, new WebText('2017 - actualmente', '2017 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Versión Mobile del SAT, para agil acceso a las consultas de deudas y pagos online.', 'Mobile version of the SAT, for quick access to online debt and payment inquiries.'),
        new WebText(
          'Luego de dos años del lanzamiento de SAT Web, lanzamos la versión Mobile, disponible para Android, iOS y Windows Phone.<br>Esta aplicación fue diseñada como una simplificación de la versión Web, está orientada a la consulta de deuda, generación de recibos y pagos online. Además permite consultar información útil, como vencimientos y lugares de pago entre otras.', 
          'After two years of the launch of SAT Web, we launched the Mobile version, available for Android, iOS and Windows Phone.<br>This application was designed as a simplification of the Web version, it is oriented to the consultation of debt, generation of receipts and online payments. It also allows you to consult useful information, such as due dates and payment locations, among others.'
        ),
      ),
      new Project(
        'Plataforma de Soporte a Contribuyentes', 
        2016, new WebText('2016 - actualmente', '2016 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Plataforma creada para brindar asistencia a los contribuyentes que acceden al SAT.', 'Platform created to provide assistance to taxpayers who access the SAT.'),
        new WebText(
          'Surge de la necesidad de dar soporte a los contribuyentes en el uso del sistema SAT. Permite a los operadores acceder a la misma información que ve el contribuyente, realizar gestión de usuarios (activaciones, cambios de contraseña, baja, entre otras). Con el tiempo, la aplicación fue sumando más herramientas; tanto de soporte a contribuyentes, como así también herramientas administrativas de uso interno de los empleados.',
          'It arises from the need to support taxpayers in the use of the SAT system. It allows operators to access the same information that the taxpayer sees, perform user management (activations, password changes, cancellation, among others). Over time, the application was adding more tools; both support to taxpayers, as well as administrative tools for internal use of employees.'
        ),
      ),
      new Project(
        'SAT - Derechos de Cementerio', 
        2015, new WebText('2015 - actualmente', '2015 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Se crea el módulo de estadisticas para analizar el uso del SAT.', 'The statistics module is created to analyze the use of the SAT.'),
        new WebText(
          'Se incorpora al SAT la tasa de Derecho de Cementerio, permitiendo a los contribuyentes, gestionar sus cuotas y el pago online de las mismas, agilizando el procedimiento y evitando la asistencia presencial de los vecinos.', 
          'The Cemetery Law fee is incorporated into the SAT, allowing taxpayers to manage their fees and their online payment, expediting the procedure and avoiding the attendance of the neighbors.'
        ),
      ),
      new Project(
        'SAT - Estadísticas', 
        2016, new WebText('2016 - actualmente', '2016 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Al Portal de Soporte de Contribuyentes, se le suma una nueva sección de Reportes y Estadísticas. Esta incorporación permite visualizar las cantidades de accesos y usos del SAT; para así tener un feedback sobre el sistema y poder tomar acciones en consecuencia.', 
          'A new Reports and Statistics section is added to the Taxpayer Support Portal. This incorporation allows to visualize the amounts of access and uses of the SAT; in order to have feedback on the system and be able to take action accordingly.'
        ),
      ),
      new Project(
        'Consultas y Reclamos', 
        2017, new WebText('2017 - actualmente', '2017 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Se crea la plataforma de Consultas y Reclamos, para temas vinculados a la atención tributaria.', 'The Consultations and Claims platform is created, for issues related to tax attention.'),
        new WebText(
          'Con la Plataforma de Soporte a Contribuyentes ya funcionando, se decidió mejorar la calidad de atención y también organizar al equipo; así nació la necesidad de incorporar una nueva sección dentro del SAT para que los usuarios pudieran realizar Consultas o Reclamos sobre los temas relacionados a la Atención Tributaria, en todas sus aristas. Esto cuenta también, con una bandeja de entrada para que los empleados puedan tomar o derivar los casos que llegan.', 
          'With the Taxpayer Support Platform already in operation, it was decided to improve the quality of care and also organize the team; Thus, the need was born to incorporate a new section within the SAT so that users could make inquiries or complaints about the issues related to Tax Attention, in all its edges. This also has an inbox for employees to take or derive cases that arrive.'
        ),
      ),
      new Project(
        'Gestor de Trámites', 
        2017, new WebText('2017 - actualmente', '2017 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Sistema de Gestion de Trámites Integral', 'Integral Procedures Management System'),
        new WebText(
          'Este proyecto se estuvo trabajando por dos años y a principio de 2016 llega a nuestras manos; para que lo llevemos a la práctica; trabajando 6 meses para poder lanzarlo.<br>El sistema permite a los empleados de la municipalidad iniciar los trámites, consultando datos propios y con la documentación adjuntada. Luego el sistema, a través de un balanceador de carga, asigna el trámite a los usuarios que estén habilitados para continuar con el Workflow. A través de los distintos pasos del Workflow propio para cada trámite, se llega a la finalización del mismo, registrando los datos finales en el sistema principal de la municipalidad.<br>La aplicación cuenta con toda una sección de administración, donde se pueden configurar los Workflows y niveles de asignaciones a los mismos; límites de carga, reasignaciones, y finalmente, la posibilidad de diseñar e implementar trámites simples.<br>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.', 
          'This project was working for two years and at the beginning of 2016 comes to our hands; for us to put it into practice; working 6 months to be able to launch it.<br>The system allows the employees of the municipality to initiate the procedures, consulting their own data and with the attached documentation. Then the system, through a load balancer, assigns the process to users who are enabled to continue with the Workflow. Through the different steps of the Workflow itself for each procedure, it is completed, registering the final data in the main system of the municipality.<br>The application has a whole administration section, where you can configure Workflows and assignment levels to them; load limits, reallocations, and finally, the possibility of designing and implementing simple procedures.'
        ),
      ),
      new Project(
        'SAT - Régimen Simplificado', 
        2018, new WebText('2018 - actualmente', '2018 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Se incorpora una nueva Tasa Comercial, para pequeños comerciantes del municipio.', 'A new Commercial Rate is incorporated for small merchants in the municipality.'),
        new WebText(
          'A fines del 2017 se ve la necesidad de incorporar esta nueva tasa Comercial al SAT. Esta tasa permitía a los pequeños comerciantes tener una cuota fija mensual, en base a una Declaración Jurada anual. Si bien la incorporación como tasa tenia todas las funcionalidades habituales (consulta de deuda y pagos); esta requería toda una sección propia para el manejo de las declaraciones, como así también, un Login y sección de registración aparte.', 
          'At the end of 2017, it is necessary to incorporate this new Commercial rate to the SAT. This rate allowed small merchants to have a fixed monthly fee, based on an annual Affidavit. Although the incorporation as a rate had all the usual functionalities (consultation of debt and payments); This required a whole section of its own for handling declarations, as well as a separate Login and registration section.'
        ),
      ),
      new Project(
        'Web de Ingresos Públicos', 
        2018, new WebText('2018 - actualmente', '2018 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Sitio institucional para la Secretaría de Ingresos Públicos.', 'Institutional site for the Ministry of Public Revenue.'),
        new WebText(
          'Se implementa una solución Web para la Secretaria de Ingresos Públicos. El objetivo de la misma es ofrecer información útil, novedades y accesos frecuentes a los contribuyentes. Puedes ver como quedó ingresando a <a href="https://www.vicentelopez.gov.ar/ingresos-publicos/ " target="_blank">https://www.vicentelopez.gov.ar/ingresos-publicos/</a>', 
          'A web solution for the Public Revenue Secretariat is implemented. Its objective is to offer useful information, news and frequent access to taxpayers. You can see how it was entering to <a href="https://www.vicentelopez.gov.ar/ingresos-publicos/ " target="_blank">https://www.vicentelopez.gov.ar/ingresos-publicos/</a>'
        ),
      ),
      new Project(
        'SAT - Boleta Electrónica', 
        2019, new WebText('2019 - actualmente', '2019 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Se incorpora en el SAT las adhesiones a Boleta Electrónica', 'Adhesions to Electronic Ballot are incorporated into the SAT'),
        new WebText(
          'A partir de la aprobación de una ordenanza, se lanza el proyecto de adhesión a boleta electrónica. Esto permite que el contribuyente se adhiera al sistema para recibir su boleta al correo que declara. Debido a esto, se creó una nueva sección dentro del SAT, en donde la persona puede realizar la adhesión o bien cancelara; sobre aquellas cuentas en las cuales es titular.', 
          'Upon approval of an ordinance, the electronic ballot adhesion project is launched. This allows the taxpayer to adhere to the system to receive his ballot to the mail he declares. Because of this, a new section was created within the SAT, where the person can make the membership or cancel; on those accounts in which he is the holder.'
        ),
      ),
      new Project(
        'FIT', 
        2018, new WebText('2018 - actualmente', '2018 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Sistema especializado en el proceso de Fiscalización Tributaria.', 'Specialized system in the Tax Inspection process.'),
        new WebText(
          'Esta aplicación está diseñada para seguir todo el proceso de Fiscalización Tributaria, lo más específico posible, de modo tal que permite realizar controles y restricciones sobre el accionar de los actores en el proceso. El sistema cuenta con muchas etapas en las cuales se requieren recuperar información de contribuyentes, subir archivos, realizar aprobaciones, rechazos, modificaciones, cálculos, registrar conflictos, notificaciones, informes autogenerados, resoluciones; y algunas cuestiones más que se ven involucradas en el proceso de dicha fiscalización.<br>La aplicación ofrece una ventaja para los distintos participantes, ya que al no ser un aplicativo de escritorio; puede ser trabajado a distancia, incluso, desde el domicilio del contribuyente.', 
          'This application is designed to follow the entire Tax Inspection process, as specific as possible, in a way that allows controls and restrictions on the actions of the actors in the process. The system has many stages in which it is required to retrieve taxpayer information, upload files, approve, reject, modify, calculate, register conflicts, notifications, self-generated reports, resolutions; and some other issues that are involved in the process of said inspection.<br>The application offers an advantage for different participants, since it is not a desktop application; It can be worked remotely, even from the domicile of the taxpayer.'
        ),
      ),
    ]),
    new Client('mgsm.png', 'Mun. San Martín', 'mgsm', ['filterMunicipalities'], [
      new Project(
        'Sistema de Trámites Online', 
        2018, new WebText('2018 - actualmente', '2018 - now'),
        ['filterImportant', 'filterDevelopment'], 
        new WebText('Aplicación web que les permite a los contribuyentes del partido de General San Martín iniciar trámites desde la comodidad de su casa y a los empleados municipales realizar el seguimiento de dichos trámites.', 'Web application that allows taxpayers of the General San Martín party to initiate procedures from the comfort of their home and to municipal employees to track these procedures.'),
        new WebText(
          'Desarrollamos una Plataforma de Trámites Online; en donde se busca descongestionar los CAC de la municipalidad y ofrecer la oportunidad de que los contribuyentes puedan gestionar sus trámites de manera online.<br>Cada empleado puede chequear los trámites presentados, ver su estado, cargar información y documentación y verificar el movimiento en la cuenta corriente (que se realiza automáticamente al finalizar el trámite).', 
          'We develop an Online Procedures Platform; where it is sought to decongest the CACs of the municipality and offer the opportunity for taxpayers to manage their procedures online. <br> Each employee can check the procedures presented, view their status, upload information and documentation and verify the movement in the checking account (which is done automatically at the end of the procedure).'
        ),
      ),
      new Project(
        'STO - Trámites Personalizados', 
        2019, new WebText('2018 - actualmente', '2018 - now'),
        ['filterRelevant', 'filterDevelopment'], 
        new WebText('Se mejoran las características y funcionalidades de los trámites personalizables', 'The characteristics and functionalities of the customizable procedures are improved'),
        new WebText(
          'Se mejoran las características y funcionalidades de los trámites personalizables; incorporándose más tipos de campos, secciones reutilizables, características puntuales. También, se agrega todo un nuevo acceso para contribuyentes no registrados en DFE y un manejo de notificaciones, tanto por mail, como a través de la misma aplicación.<br>Por último, se rediseña el motor de Workflow, para que tenga una mayor capacidad de personalización, y así, ajustarse a las necesidades de la Municipalidad.', 
          'The characteristics and functionalities of the customizable procedures are improved; incorporating more types of fields, reusable sections, specific characteristics. Also, it adds a whole new access for taxpayers not registered in DFE and a handling of notifications, both by mail, and through the same application.<br>Finally, the Workflow engine is redesigned, so that it has a greater capacity for customization, and thus, adjust to the needs of the Municipality.'
        ),
      ),
    ]),
    new Client('hsi.png', 'Hipódromo de San Isidro', 'hsi', ['filterHypodromes'], [
      new Project(
        'JKey', 
        2019, new WebText('2019', '2019'),
        ['filterImportant', 'filterDevelopment'], 
        new WebText('Aplicación Mobile que brinda información a los apostadores y le permite al personal del hipódromo monitorear y detectar usos fraudulentos.', 'Mobile application that provides information to bettors and allows racetrack personnel to monitor and detect fraudulent uses.'),
        new WebText(
          'Aplicación Mobile que brinda información a los apostadores sobre los dividendos, combinaciones y resultados de las carreras del Hipódromo. Al mismo tiempo, le permite al personal interno monitorear las conexiones y detectar usos fraudulentos para accionar sobre los mismos.', 
          'Mobile application that provides information to bettors about dividends, combinations and race results of the Racecourse. At the same time, it allows internal staff to monitor connections and detect fraudulent uses to act on them.'
        ),
      ),
      new Project(
        'Dividendos', 
        2019, new WebText('2019 - actualmente', '2019 - now'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Se implementó una aplicación Web que ofrece la información de los dividendos, ganadores, combinaciones y resultados de las carreras en vivo. Esto se muestra en los televisores que se encuentran en el hipódromo para los espectadores.', 
          'A Web application was implemented that offers information on dividends, winners, combinations and results of live races. This is shown on the televisions that are in the racecourse for the spectators.'
        ),
      ),
    ]),
    new Client('proanalisis.png', 'Proanalisis', 'pro', ['filterLaboratories'], [
      new Project(
        'ProLabs', 
        2018, new WebText('2018 - actualmente', '2018 - now'),
        ['filterImportant', 'filterDevelopment'], 
        new WebText('Aplicación web que centraliza el proceso administrativo del laboratorio de una forma ágil y simple.', 'Web application that centralizes the administrative process of the laboratory in an agile and simple way.'),
        new WebText(
          'Aplicación web que centraliza el proceso administrativo del laboratorio de una forma ágil y simple, siguiendo las normas de las “Buenas prácticas de Laboratorio”. La misma permite automatizar la generación de Informes mediante templates configurables por el usuario, agilizando el proceso de carga de datos repetitivos por parte de los analistas.', 
          'Web application that centralizes the administrative process of the laboratory in an agile and simple way, following the rules of the “Good Laboratory Practices”. It allows to automate the generation of Reports through templates configurable by the user, speeding up the process of loading repetitive data by analysts.'
        ),
      ),
    ]),
    new Client('tenaris.jpg', 'Tenaris', 'ten', ['filterMetallurgical'], [
      new Project(
        'Consultoría SCM / Servicio Soporte SCM', 
        2009, new WebText('2009 - 2017', '2009 - 2017'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Soporte y administración de las herramientas de Software Change Managment principalmente CA SCM Harvest y TFS.<br>Administración el ciclo de vida completo de más de 400 proyectos distribuidos en las distintas sedes (Argentina, México, Italia)', 
          'Support and administration of Software Change Managment tools mainly CA SCM Harvest and TFS. <br> Administration the complete life cycle of more than 400 projects distributed in different locations (Argentina, Mexico, Italy)'
        ),
      ),
    ]),
    new Client('telecom.jpg', 'Telecom', 'tel', ['filterTelecommunications'], [
      new Project(
        'Consultoría SCM', 
        2006, new WebText('2006 - 2010', '2006 - 2010'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Implementación CA SCM Harvest. Diseño y construcción de Ciclo de Vida.', 
          'CA SCM Harvest implementation. Design and construction of Life Cycle.'
        ),
      ),
      new Project(
        'Devops Consulting Service', 
        2006, new WebText('2006 - 2010', '2006 - 2010'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Soporte y administración de CA SCM Harvest. Implementación de procesos de automatización de construcción y despliegue para los distintos ambientes de test y producción.', 
          'Support and administration of CA SCM Harvest. Implementation of construction and deployment automation processes for the different test and production environments.'
        ),
      ),
      new Project(
        'Desarrollo a Medida', 
        2006, new WebText('2006 - 2010', '2006 - 2010'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Construcción de plugin en JSwing para administrar el versionado de objetos de base de datos Oracle desde Harvest.', 
          'Building plugin in JSwing to manage versioning of Oracle database objects from Harvest.'
        ),
      ),
    ]),
    new Client('efectivosi.jpg', 'EfectivoSI', 'efe', ['filterFinancial'], [
      new Project(
        'Consultoría SCM', 
        2010, new WebText('2010 - 2011', '2010 - 2011'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Implementación CA SCM Harvest. Diseño y construcción de Ciclo de Vida.', 
          'CA SCM Harvest implementation. Design and construction of Life Cycle.'
        ),
      ),
    ]),
    new Client('cetelem.jpg', 'Cetelem', 'cet', ['filterFinancial'], [
      new Project(
        'Consultoría SCM', 
        2011, new WebText('2011 - 2012', '2011 - 2012'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Implementación CA SCM Harvest. Diseño y construcción de Ciclo de Vida.', 
          'CA SCM Harvest implementation. Design and construction of Life Cycle.'
        ),
      ),
    ]),
    new Client('microquim.jpg', 'Microquim', 'mic', ['filterLaboratories'], [
      new Project(
        '(Prototipo) Sistema de Laboratorio', 
        2009, new WebText('2009 - 2010', '2009 - 2010'),
        ['filterRelevant', 'filterDevelopment'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Solución para Gestión Integral de Laboratorios del tipo LIMS (Laboratoy Information Managment System) que contempló todas las etapas del ciclo de vida de un servicio de análisis: presupuestación, generación de orden de trabajo, asignación de responsables, carga de resultados, circuito de aprobaciones de las distintas áreas, generación de informes, facturación y gestión cuenta corriente del cliente, etc.', 
          'Solution for Integral Management of Laboratories of the type LIMS (Laboratoy Information Management System) that contemplated all the stages of the life cycle of an analysis service: budgeting, generation of work order, assignment of managers, loading of results, circuit of approvals of the different areas, reporting, billing and customer checking account management, etc.'
        ),
      ),
    ]),
    new Client('bancocomafi.jpg', 'Banco Comafi', 'bcf', ['filterBanks'], [
      new Project(
        'Consultoría SCM / Servicio Soporte SCM', 
        2007, new WebText('2007 - 2018', '2007 - 2018'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Implementación CA SCM Harvest. Diseño y construcción de Ciclo de Vida. Soporte y administración de CA SCM Harvest. Implementación de procesos de automatización de construcción y despliegue para los distintos ambientes de test y producción.', 
          'CA SCM Harvest implementation. Design and construction of Life Cycle. Support and administration of CA SCM Harvest. Implementation of construction and deployment automation processes for the different test and production environments.'
        ),
      ),
      new Project(
        'Sistema de Reportes de Implementaciones', 
        2016, new WebText('2016', '2016'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Se desarrolló un sistema que permite generar distintos tipos de reportes, sobre el estado de las implementaciones; consumiendo datos desde la base de Harvest.', 
          'A system was developed that allows generating different types of reports on the status of the implementations; consuming data from the Harvest base.'
        ),
      )
    ]),
    // new Client('bancohipotecario.jpg', 'Banco Hipotecario', 'hip', ['filterBanks'], [
    //   new Project(
    //     'Consultoría AutoSys', 
    //     2009, new WebText('2009', '2009'),
    //     ['filterRelevant', 'filterConsultancy'],
    //     new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
    //     new WebText('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.', ''),
    //   ),
    // ]),
    new Client('pepsico.jpg', 'Pepsico', 'pep', ['filterFood'], [
      new Project(
        'Consultoría SCM', 
        2011, new WebText('2011 - 2012', '2011 - 2012'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Implementación CA SCM Harvest. Diseño y construcción de Ciclo de Vida.', 
          'CA SCM Harvest implementation. Design and construction of Life Cycle.'
        ),
      ),
      new Project(
        'Desarrollo a Medida', 
        2011, new WebText('2011 - 2012', '2011 - 2012'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Se desarrolló un Dashboard en JSwing para el seguimiento del estado de los paquetes de implementación de los distintos proyectos administrados por Harvest.',
          'A Dashboard was developed in JSwing to monitor the status of the implementation packages of the different projects managed by Harvest.'
        ),
      ),
    ]),
    new Client('edenor.jpg', 'Edenor', 'ede', ['filterElectrical'], [
      new Project(
        'Consultoría SCM', 
        2009, new WebText('2009', '2009'),
        ['filterRelevant', 'filterConsultancy'],
        new WebText('Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.', ''),
        new WebText(
          'Implementación CA SCM Harvest. Diseño y construcción de Ciclo de Vida.', 
          'CA SCM Harvest implementation. Design and construction of Life Cycle.'
        ),
      ),
    ]),
  ],

  /* FILTERS */
  all: new WebText('Todos', 'All'),
  priority: new WebText('Relevancia', 'Relevance'),
  services: new WebText('Servicios', 'Services'),
  heading: new WebText('Rubros', 'Heading'),
  filterImportant: new WebText('Alta', 'High'),
  filterRelevant: new WebText('Media', 'Medium'),
  filterDevelopment: new WebText('Desarrollo', 'Development'),
  filterConsultancy: new WebText('Consultoría', 'Consultancy'),
  filterTelecommunications: new WebText('Telecomunicaciones', 'Telecommunications'),
  filterMunicipalities: new WebText('Municipios', 'Municipalities'),
  filterLaboratories: new WebText('Laboratorios', 'Laboratories'),
  filterElectrical: new WebText('Distribución Eléctrica', 'Electrical'),
  filterBanks: new WebText('Bancos', 'Banks'),
  filterFinancial: new WebText('Entidades Financieras', 'Financial Entities'),
  filterHypodromes: new WebText('Hipódromos', 'Hypodromes'),
  filterMetallurgical: new WebText('Metalúrgica', 'Metallurgical'),
  filterFood: new WebText('Alimenticia', 'Food Industry'),
}