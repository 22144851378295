import { Component, OnInit } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';
import { MessageService } from 'src/app/services/messages/message.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ContactFormService } from '../../services/contactForm/contact-form.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  moreDetails: boolean;
  form: FormGroup;
  stagesForm: FormGroup;
  solutionsForm: FormGroup;
  stages = [
        { name: this._information.getText('contactDev'), id: 'contactDev'},
        { name: this._information.getText('contactDis'), id: 'contactDis' },
        { name: this._information.getText('contactAna'), id: 'contactAna'},
        { name: this._information.getText('contactImp'), id: 'contactImp'},
        { name: this._information.getText('contactMan'), id: 'contactMan'},
        { name: this._information.getText('contactQC'), id: 'contactQC'},
        { name: this._information.getText('contactQA'), id: 'contactQA'},
  ];

  solutions = [
    { name: this._information.getText('contactWeb'), id: 'contactWeb'},
    { name: this._information.getText('contactMob'), id: 'contactMob'},
    { name: this._information.getText('contactDesk'), id: 'contactDesk'},
    { name: this._information.getText('contactWS'), id: 'contactWS'},
    { name: this._information.getText('contactMic'), id: 'contactMic'},
  ];

  constructor(public _information: InformationService, public _MessageService: MessageService, 
              private fb: FormBuilder, private contactService: ContactFormService) {
    this.contactService.changeState(false);
    this.solutionsForm = this.fb.group({
      name: this.fb.array([])
    });
    this.stagesForm = this.fb.group({
      name: this.fb.array([])
    });
  }

  onStageChange(name: string, isChecked: boolean) {
    const nameFormArray = this.stagesForm.controls.name as FormArray;

    if (isChecked) {
      nameFormArray.push(new FormControl(name));
    } else {
      const index = nameFormArray.controls.findIndex(x => x.value === name);
      nameFormArray.removeAt(index);
    }

    console.log(this.stagesForm.value);
  }

  onSolutionChange(name: string, isChecked: boolean) {
    const nameFormArray = this.solutionsForm.controls.name as FormArray;

    if (isChecked) {
      nameFormArray.push(new FormControl(name));
    } else {
      const index = nameFormArray.controls.findIndex(x => x.value === name);
      nameFormArray.removeAt(index);
    }
  }

  ngOnInit() {
    this.contactService.currentState.subscribe(currentState => this.moreDetails = currentState);

    this.form = new FormGroup({
      name: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.email]),
      message: new FormControl(null, [Validators.required]),
      company: new FormControl(null, [Validators.required]),
    });
  }

  contactForm() {
    const solutionList: string[] = this.solutionsForm.get('name').value;
    const stageList: string[] = this.stagesForm.get('name').value;
    const unForm = {
      nombre: this.form.get('name').value,
      email: this.form.get('email').value,
      mensaje: this.form.get('message').value.concat(),
      empresa: this.form.get('company').value,
      soluciones: solutionList.length === 0 ? '' : solutionList.join(','),
      etapas: stageList.length === 0 ? '' : stageList.join(','),
    };
    this._MessageService.sendMessage(unForm).subscribe(() => {
      alert('Se envió el mail correctamente');
    });

    this.form.reset();
    this.contactService.changeState(false);
    this.solutionsForm = this.fb.group({
      name: this.fb.array([])
    });
    this.stagesForm = this.fb.group({
      name: this.fb.array([])
    });
  }

  public setMoreDetails(visible: boolean) {
    this.contactService.changeState(visible);
    if (!visible) {
      this.solutionsForm = this.fb.group({
        name: this.fb.array([])
      });
      this.stagesForm = this.fb.group({
        name: this.fb.array([])
      });
    }
  }

}
