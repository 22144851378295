import { Component, Output, EventEmitter } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';
import { ContactFormService } from '../../services/contactForm/contact-form.service';

@Component({
  selector: 'app-make',
  templateUrl: './make.component.html',
  styleUrls: ['./make.component.scss']
})
export class MakeComponent {

  @Output() contactTechForm = new EventEmitter<any>();

  constructor(public _information: InformationService, private contactService: ContactFormService) { }

  goToContact() {
    const element = document.getElementById('cognisysLogo');
    element.scrollIntoView();
    this.contactService.changeState(true);
  }

}
