import { Component } from '@angular/core';
import { Timeline, TimelineProject, TimelineClient } from 'src/assets/model/timeline.model';
import { INFO } from 'src/assets/model/info.model';
import { Client } from 'src/assets/model/client.model';
import { InformationService } from 'src/app/services/information/information.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent {

  timeline: Timeline;
  selectedClient: TimelineClient;
  selectedProject: TimelineProject;
  closeEvent: boolean;

  // filters
  heading = ['all', 'filterFood', 'filterBanks', 'filterElectrical', 'filterFinancial', 'filterHypodromes', 'filterLaboratories', 'filterMetallurgical', 'filterMunicipalities', 'filterTelecommunications'];
  selectedHeading: string;
  timelineFiltered: Timeline;

  constructor(public _information: InformationService) {
    this.timeline = new Timeline();
    this.closeEvent = false;
    this.selectedHeading = this.heading[0];
    INFO.clients.forEach(c => {
      c.projects.forEach(p => {
        let timelineClient = this.searchClient( c );
        timelineClient.projects.push( new TimelineProject( p.name, p.summary, p.detail, p.tags, p.year, p.years ) );
      });
    });
    this.filter();
  }

  showDetails( client: TimelineClient, project: TimelineProject ): void {
    this.selectedClient = client;
    this.selectedProject = project;
  }

  closeDetail(): void {
    this.closeEvent = true;
    setTimeout(() => {
      this.selectedClient = undefined;
      this.selectedProject = undefined;
      
      this.closeEvent = false;
    }, 350);
  }

  private searchClient( client: Client ): TimelineClient {
    for (const c of this.timeline.clients) {
      if (c.name === client.name) {
        return c;
      }
    }

    let timelineClient = new TimelineClient( client.name, client.img, client.tags );
    this.timeline.clients.push( timelineClient );
    
    return timelineClient;
  }

  getSortClients() {
    return this.timelineFiltered.clients.sort((a, b) => {
      return <any>b.name - <any>a.name;
    });
  }

  getSortProjects( projects: TimelineProject[]) {
    return projects.sort((a, b) => {
      return <any>b.year - <any>a.year;
    });
  }

  filter() {
    this.timelineFiltered = new Timeline();
    this.timelineFiltered.clients = this.timeline.clients.filter( (c) => (this.selectedHeading === 'all' || c.tags.includes( this.selectedHeading )) );    
  }

  selectHeading( option: string ): void {
    this.selectedHeading = option;
    this.filter();
  }
}