import { Component, OnInit } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';
import { ClientView } from 'src/assets/model/client.model';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-tile-create',
  templateUrl: './tile-create.component.html',
  styleUrls: ['./tile-create.component.scss']
})
export class TileCreateComponent implements OnInit {

  clients: ClientView[];

  constructor(public _information: InformationService, private router: Router) {
    this.clients = this._information.getClients( 6 );
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  }

  toggle( view: ClientView ) {
    view.expanded = !view.expanded;
  }
}
