import { Component, OnInit } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-tile-make',
  templateUrl: './tile-make.component.html',
  styleUrls: ['./tile-make.component.scss']
})
export class TileMakeComponent implements OnInit{

  constructor(public _information: InformationService, private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  }
}
