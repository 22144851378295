import { Component } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';

@Component({
  selector: 'app-we',
  templateUrl: './we.component.html',
  styleUrls: ['./we.component.scss']
})
export class WeComponent {

  constructor(public _information: InformationService) { }
}
