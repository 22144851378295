import { Injectable } from '@angular/core';
import { LANG } from 'src/assets/model/languages.model';
import { INFO } from 'src/assets/model/info.model';
import { WebText } from 'src/assets/model/web-text.model';
import { Client, ClientView } from 'src/assets/model/client.model';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  language: LANG;

  constructor() {
    this.language = LANG.es;
  }

  ngOnInit(): void {}

  getText( key: string ): string {
    let text: WebText = INFO[key];
    return this.selectText( text );
  }

  selectText( text: WebText ): string {
    if ( this.isSpanish() ) {
      return text.es;
    } else if ( this.isEnglish() ) {
      return text.en;
    }
  }

  isSpanish(): boolean {
    return this.language == LANG.es;
  }

  isEnglish(): boolean {
    return this.language == LANG.en;
  }

  setSpanish() {
    this.language = LANG.es;
  }
  
  setEnglish() {
    this.language = LANG.en;
  }

  getClients( count: number ): ClientView[] {
    let c = [];

    for (let i = 0; i < count; i++) {
      c.push( new ClientView(INFO.clients[i], false) );
    }

    return c;
  }

  getSummaryFirstProect( client: Client ): string {
    if ( client.projects ) {
      return this.selectText( client.projects[0].summary );
    }
    return '-';
  }
}