import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactButtonsComponent } from './components/contact-buttons/contact-buttons.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { CarrouselComponent } from './components/carrousel/carrousel.component';
import { HomeComponent } from './components/home/home.component';
import { TileWeComponent } from './components/home/tile-we/tile-we.component';
import { TileMakeComponent } from './components/home/tile-make/tile-make.component';
import { TileCreateComponent } from './components/home/tile-create/tile-create.component';
import { WeComponent } from './components/we/we.component';
import { MakeComponent } from './components/make/make.component';
import { CreateComponent } from './components/create/create.component';
import { JobComponent } from './components/job/job.component';
import { MessageService } from './services/messages/message.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TimelineComponent } from './components/timeline/timeline.component';
import { ContactFormService } from './services/contactForm/contact-form.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ButtonUpComponent } from './components/button-up/button-up.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactButtonsComponent,
    ContactFormComponent,
    CarrouselComponent,
    HomeComponent,
    TileWeComponent,
    TileMakeComponent,
    TileCreateComponent,
    WeComponent,
    MakeComponent,
    CreateComponent,
    JobComponent,
    TimelineComponent,
    ButtonUpComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule
  ],
  providers: [MessageService, ContactFormService, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
