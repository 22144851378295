import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { WeComponent } from './components/we/we.component';
import { MakeComponent } from './components/make/make.component';
import { CreateComponent } from './components/create/create.component';
import { JobComponent } from './components/job/job.component';

const routes: Routes = [
  { 
    path: 'home', 
    component: HomeComponent,
    data: {
      title: 'home',
      keywords: 'keywordsHome',
      description:'descriptionHome',
      ogUrl: 'ogUrlHome'
    }
  },
  { 
    path: 'we', 
    component: WeComponent,
    data: {
      title: 'menuWe',
      keywords: 'keywordsWe',
      description:'descriptionWe',
      ogUrl: 'ogUrlWe'
    } 
  },
  { 
    path: 'make', 
    component: MakeComponent,
    data: {
      title: 'menuMake',
      keywords: 'keywordsMake',
      description:'descriptionMake',
      ogUrl: 'ogUrlMake'
    } 
  },
  { 
    path: 'create', 
    component: CreateComponent,
    data: {
      title: 'menuCreate',
      keywords: 'keywordsCreate',
      description:'descriptionCreate',
      ogUrl: 'ogUrlCreate'
    } 
  },
  { 
    path: 'job', 
    component: JobComponent,
    data: {
      title: 'menuJob',
      keywords: 'keywordsJob',
      description:'descriptionJob',
      ogUrl: 'ogUrlJob'
    } 
  },
  { 
    path: '', 
    component: HomeComponent,
    data: {
      title: 'home',
      keywords: 'keywordsHome',
      description:'descriptionHome',
      ogUrl: 'ogUrlHome'
    }
  },
  { 
    path: '**', 
    component: HomeComponent,
    data: {
      title: 'home',
      keywords: 'keywordsHome',
      description:'descriptionHome',
      ogUrl: 'ogUrlHome'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }