import { WebText } from './web-text.model';

export class Client {
  img: string;
  name: string;
  code: string;
  projects: Project[];
  tags: string[];

  constructor(img: string,
              name: string,
              code: string,
              tags: string[],
              projects?: Project[]) {
    this.img = img;
    this.name = name;
    this.code = code;
    this.tags = tags;
    this.projects = projects;
  }
}

export class Project {
  name: string;
  year: number;
  summary: WebText;
  detail: WebText;
  imgs: string[];
  tags: string[];
  years: WebText;

  constructor(name: string,
              year: number,
              years: WebText,
              tags: string[],
              summary: WebText,
              detail: WebText,
              imgs?: string[]) {
    this.name = name;
    this.year = year;
    this.years = years;
    this.summary = summary;
    this.detail = detail;
    this.tags = tags;
    this.imgs = imgs;
  }
}

export class ClientView {
  client: Client;
  expanded: boolean;

  constructor(client: Client,
              expanded: boolean) {
    this.client = client;
    this.expanded = expanded;
  }
}