import { Component } from '@angular/core';

@Component({
  selector: 'app-button-up',
  templateUrl: './button-up.component.html',
  styleUrls: ['./button-up.component.scss']
})
export class ButtonUpComponent {

  constructor() { }

  goUp() {
    const element = document.getElementById('navbar-header');
    element.scrollIntoView();
  }
}