import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { InformationService } from '../information/information.service';
import { ContactFormService } from '../contactForm/contact-form.service';

@Injectable({
  providedIn: 'root'
})
export class MetasService {

  constructor(private titleService: Title,
              private meta: Meta,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private _information: InformationService,
              private _contactForm: ContactFormService) { }

  updateDescription( title: string, desc: string, keywords: string, author: string ) {
    this.titleService.setTitle( this._information.getText(title) + ' | COGNISYS' );
    this.meta.updateTag({ name: 'description', content: this._information.getText(desc) });
    this.meta.updateTag({ name: 'keywords', content: this._information.getText(keywords) });
    this.meta.updateTag({ name: 'og:title', content: this._information.getText(title) });
    this.meta.updateTag({ name: 'og:description', content: this._information.getText(desc) });
}

  updateTitle(title?: string) {
    if (!title) {
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) { route = route.firstChild; }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)).subscribe((event) => {
          this.updateDescription(event['title'], event['description'], event['keywords'], event['author']);
          this._contactForm.changeState(false);
        });
    } else {
      this.titleService.setTitle(title + ' | COGNISYS');
    }
  }
}