import { Component } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor(public _information: InformationService) { }

  
}