import { Component } from '@angular/core';
import { InformationService } from 'src/app/services/information/information.service';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent {

  constructor(public _information: InformationService) { }
}