import { Component } from '@angular/core';
import { MetasService } from './services/metas/metas.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'website';

  constructor(private meta: MetasService) {
    this.meta.updateTitle();
  }
}