import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InformationService } from 'src/app/services/information/information.service';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss']
})
export class JobComponent {

  uploadedFiles: Array<File> = [];

  constructor(public _information: InformationService, private http: HttpClient) { }

  fileChange(element) {
    this.uploadedFiles = element.target.files;
  }

  upload() {
    const formData = new FormData();
    console.log(this.uploadedFiles);
    for (var i = 0; i < this.uploadedFiles.length; i++) {
        formData.append("uploads[]", this.uploadedFiles[i], this.uploadedFiles[i].name);
    }
    this.http.post('http://54.202.62.110:3000/api/upload', formData).subscribe((response) => {
         console.log('response received is ', response);
    });

    alert('Se envió el CV correctamente!');
  }
}
