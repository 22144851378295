import { WebText } from './web-text.model';

export class Timeline {
  clients: TimelineClient[];

  constructor() {
    this.clients = [];
  }
}

export class TimelineClient {
  name: string;
  img: string;
  projects: TimelineProject[];
  tags: string[];

  constructor(name: string, 
              img: string,
              tags: string[]) {
    this.name = name;
    this.img = img;
    this.projects = [];
    this.tags = tags;
  }
}

export class TimelineProject {
  name: string;
  summary: WebText;
  detail: WebText;
  tags: string[];
  year: number;
  years: WebText;

  constructor(name: string,
              summary: WebText,
              detail: WebText,
              tags: string[],
              year: number,
              years: WebText) {
    this.name = name;
    this.summary = summary;
    this.detail = detail;
    this.tags = tags;
    this.year = year;
    this.years = years;
  }
}