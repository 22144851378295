import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  private visible = new BehaviorSubject<boolean>(false);
  currentState = this.visible.asObservable();

  constructor() { }

  changeState(open: boolean) {
    console.log('Is Open?' + open);
    this.visible.next(open);
  }
}
